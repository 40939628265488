
import {
  defineComponent, onMounted, toRefs, PropType,
} from 'vue';

import TTBackButton from '@/components/ui/TTBackButton.vue';
import CoursePage from '@/views/App/Assignments/CoursePage';
import useCoursePreview from '@/domains/course/composables/useCoursePreview';

import { UUID } from '@/domains/common';

export default defineComponent({
  name: 'CoursePreview',
  components: {
    TTBackButton,
    CoursePage,
  },

  inject: ['Names'],

  props: {
    catalogueAtomId: {
      type: String as PropType<UUID>,
      required: true,
    },
  },

  setup(props) {
    const { catalogueAtomId } = toRefs(props);

    const {
      atom,
      isSelfAssigned,
      steps,
      isLoading,
      isError,
      userTrackAssign,
      init,
    } = useCoursePreview({ catalogueAtomId });

    onMounted(async () => {
      await init();
    });

    return {
      atom,
      isSelfAssigned,
      steps,
      isLoading,
      isError,
      userTrackAssign,
      init,
    };
  },
});
