
import Vue, { PropType } from 'vue';
import { MY_COMPANY_PROVIDER } from './constants';
import { IContentProvider } from './types';

export default Vue.extend({
  name: 'MyCompanyContentProvider',
  props: {
    value: {
      type: Array as PropType<IContentProvider['id'][]>,
      default: [] as IContentProvider['id'][],
    },
    items: {
      type: Array as PropType<IContentProvider[]>,
      default: [] as IContentProvider[],
    },
  },
  data() {
    return {
      MY_COMPANY_PROVIDER,
    };
  },
  computed: {
    externalProvidersIds(): string[] {
      return this.items.filter((provider) => provider.id !== MY_COMPANY_PROVIDER.id).map((p) => p.id);
    },
    isExternalProvidersSelected(): boolean {
      if (!this.externalProvidersIds.length || !this.internalValue) return false;
      let isIncluded = true;
      this.externalProvidersIds.forEach((external) => {
        if (isIncluded) isIncluded = this.internalValue.includes(external);
      });

      return isIncluded;
    },
    internalValue: {
      get(): string[] {
        return this.value || [];
      },
      set(ids: string[]) {
        this.$emit('input', ids === null ? [] : ids);
      },
    },
  },
  methods: {
    externalProvidersClickHandler() {
      const myCompanyProviderId = MY_COMPANY_PROVIDER.id;
      const isMyCompanyChecked = this.internalValue.includes(myCompanyProviderId);
      if (!this.isExternalProvidersSelected) {
        this.internalValue = isMyCompanyChecked
          ? [myCompanyProviderId, ...this.externalProvidersIds]
          : this.externalProvidersIds;
      } else {
        this.internalValue = isMyCompanyChecked ? [myCompanyProviderId] : [];
      }
    },
  },
});
