/* eslint-disable import/prefer-default-export */
import { UUID } from '@/domains/common';
import { IAtom, AtomSource } from '@/domains/atom';
import { MY_COMPANY_PROVIDER } from '@/domains/content-provider';

/**
 * Добавление моей компании в список  контент провайдеров атомов
 * @param {IAtom} atom- Атом
 * @returns {IAtom}
 */
export const formatAtomByMyCompanyProvider = <T extends IAtom>(atom: T): T => {
  if (atom.source === AtomSource.MIXED || atom.source === AtomSource.MY_COMPANY) {
    atom.providers.push(MY_COMPANY_PROVIDER);
  }
  return atom;
};

/**
 * Добавление моей компании в список  контент провайдеров атомов
 * @param {atoms[]} atoms- массива атомов
 */
export const formatAtomsByMyCompanyProvider = <T extends IAtom>(atoms: T[]): T[] =>
  atoms.map((atom) => formatAtomByMyCompanyProvider(atom));

/**
 * Форматирование провайдеров для запроса получения атомов
 * @param {UUID[]} providers- массива провайдеров
 */
export const formatRequestProvidersByMyCompany = (providers: UUID[]) => {
  if (Array.isArray(providers) && providers.includes(MY_COMPANY_PROVIDER.id)) {
    return {
      ids: providers.filter((p) => p !== MY_COMPANY_PROVIDER.id),
      self_provider: true,
    };
  }

  return {
    ids: providers,
    self_provider: false,
  };
};
