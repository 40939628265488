import { ContentProviderStatus, IContentProvider } from './types';

/* eslint-disable import/prefer-default-export */
export const MY_COMPANY_PROVIDER: IContentProvider = {
  id: 'my_company',
  siteLink: '',
  title: 'Моя компания',
  description: '',
  status: ContentProviderStatus.ACTIVE,
};
