import { UUID } from '../common';

export enum ContentProviderStatus {
  DRAFT = 'draft',
  ACTIVE = 'active',
  BLOCKED = 'blocked',
  DELETED = 'deleted'
}

export interface IContentProvider {
  /** @property ID провайдера */
  id: UUID;
  /** @property Название провайдера */
  title: string;
  /** @property Ссылка на сайт провайдера */
  siteLink: string;
  /** @property Статус провайдера: draft, active, blocked, deleted */
  status: ContentProviderStatus;
  /** @property Описание провайдера */
  description: string;
}
