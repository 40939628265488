import { computed, ref, Ref } from 'vue';
import { useRouter } from 'vue-router/composables';
import { useDi } from '@/plugins';
import { useI18n } from '@/plugins/vue-i18n';
import * as CatalogueService from '@/services/api/catalogue';
import { formatAtomByMyCompanyProvider } from '@/utils/atom';
import * as AssignmentService from '@/services/api/lxp-assignment';
import { UUID } from '@/domains/common';
import { useCourseProvider } from '@/domains/course';
import { ICourse } from '../types';
import { Names } from '@/plugins/vue-router';
import { treeMap } from '@/common/tree/treeMap';
import { AtomType, IAtom } from '@/domains/atom';

type IUseCoursePreviewProps = {
  catalogueAtomId: Ref<UUID>;
};

export default function useCoursePreview({ catalogueAtomId }: IUseCoursePreviewProps) {
  const di = useDi();
  const router = useRouter();
  const { t, td } = useI18n();
  const atom = ref<IAtom>();
  const isLoading = ref<boolean>(false);
  const isError = ref<boolean>(false);
  const isSelfAssigned = ref<boolean>(false);
  const courseId = ref<ICourse['id'] | null>(null);

  const { steps } = useCourseProvider({ id: courseId });

  const stepsChildren = computed(() => {
    const stepsTree = treeMap(steps.value, (node) => {
      return {
        ...node,
        isDisabled: true,
      };
    });

    return stepsTree.children;
  });

  const fetchAtom = async () => {
    if (!catalogueAtomId.value) {
      throw new Error('catalogueAtomId not found!');
    }

    try {
      const response = await CatalogueService.atomGet(catalogueAtomId.value);

      atom.value = formatAtomByMyCompanyProvider(response) as IAtom;
      courseId.value = response.atomContentId;
    } catch (e: any) {
      if (e?.response.status === 403) {
        await router.replace({
          name: Names.R_APP_LEARNING_TRACK_EMPTY,
        });
      } else {
        di.notify.error({
          content: t('useCoursePreview.errors.noAtom'),
        });
      }
    }
  };

  const userTrackAssign = async () => {
    try {
      const { assignmentUserId } = await AssignmentService.assign({
        paths: {
          catalogueAtomId: catalogueAtomId.value,
        },
      });

      const { playerSessionId } = await AssignmentService.assignmentRun({
        paths: {
          assignmentUserId,
        },
        data: {
          catalogueAtomMainId: catalogueAtomId.value,
        },
      });

      if (assignmentUserId && playerSessionId) {
        await router.push({
          name: Names.R_APP_LEARNING_ASSIGNMENT_TRACK_VIEW,
          params: {
            assignmentId: String(assignmentUserId),
            trackSessionId: String(playerSessionId),
            atomType: AtomType.TRACK,
          },
        });
      }
    } catch (e: any) {
      console.error(e);
      di.notify.error({
        content: e.response?.data?.errors?.map(
          ({ message, type }: { message: string, type: 'already_assigned' }) => {
            return td(`assignment.error.${type}`, message);
          },
        ).join(', '),
      });
    }
  };

  const init = async () => {
    isLoading.value = true;
    try {
      await fetchAtom();
    } catch (e) {
      isError.value = true;
      console.error(e);
    } finally {
      isLoading.value = false;
    }
  };

  return {
    atom,
    isSelfAssigned,
    steps: stepsChildren,
    isLoading,
    isError,
    userTrackAssign,
    init,
  };
}
